/* Fonts */

@font-face {
  font-family: 'open-sans-regular';
  src: url('../font/open-sans-regular.woff') format('woff');
}
@font-face {
  font-family: 'open-sans-bold';
  src: url('../font/open-sans-bold.woff') format('woff');
}
@font-face {
  font-family: 'open-sans-italic';
  src: url('../font/open-sans-italic.woff') format('woff');
}
@font-face {
  font-family: 'open-sans-semi-bold';
  src: url('../font/open-sans-semi-bold.woff') format('woff');
}
@font-face {
  font-family: 'open-sans-light';
  src: url('../font/open-sans-light.woff') format('woff');
}
@font-face {
  font-family: 'open-sans-extra-bold';
  src: url('../font/open-sans-extra-bold.woff') format('woff');
}

/*
@font-face {
    font-family: 'Aller Regular';
    src: url('./assets/font/aller_rg-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Helvetica-Bold';
    src: url('./assets/font/helvetica-bold.woff') format('woff');
}

@font-face {
    font-family: 'HelveticaNeue-UltraLight';
    src: url('./assets/font/HelveticaNeue-UltraLight.woff') format('woff');
}

@font-face {
    font-family: 'marker felt';
    src: url('./assets/font/Marker-Felt.woff') format('woff');
}
*/
/* fallback */
// @font-face {
//     font-family: 'Material Icons';
//     font-style: normal;
//     font-weight: 400;
//     src: url("./assets/font/Material-Icons.woff2") format('woff2');
//   }

  // .material-icons {
  //   font-family: 'Material Icons';
  //   font-weight: normal;
  //   font-style: normal;
  //   font-size: 24px;
  //   line-height: 1;
  //   letter-spacing: normal;
  //   text-transform: none;
  //   display: inline-block;
  //   white-space: nowrap;
  //   word-wrap: normal;
  //   direction: ltr;
  //   -webkit-font-feature-settings: 'liga';
  //   -webkit-font-smoothing: antialiased;
  // }
