/* You can add global styles
to this file, and also import other style files */

//@import '~bootstrap/dist/css/bootstrap.min.css';
// @import '~material-icons/iconfont/material-icons.css';

@import './assets/styles/colors.scss';
body {
  overflow: hidden;
}
.floatLeft {
  float: left;
}
.floatRight {
  float: right;
}
.mousePointer {
  cursor: pointer !important;
}
.mouseDisabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}
.mouseAuto {
  cursor: auto !important;
}
.iconSpriteImage {
  background-image: url(./assets/images/sprite_image_03_Aug_2018.png);
  background-repeat: no-repeat;
}
.positionRelative {
  position: relative;
}
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

@import './assets/styles/fonts.scss';
@import './assets/styles/colors.scss';

$font-family: 'open-sans-regular';
$water-mark-font: 'open-sans-bold';
$font-color: #000;

body {
  font-family: $font-family !important;
  color: $font-color;
  height: 100%;
}

input,
textarea,
select,
button {
  font-family: $font-family !important;
  font-weight: normal !important;
}

.container {
  min-height: 100%;
  width: 100%;
  margin: auto;
}

.noPadding {
  padding: 0 !important;
}

.mat-dialog-container {
  position: relative;
}

/* style for pre-loader */
.preloader-chasing-squares .square {
  animation: focusfade 2.8s infinite;
}

.preloader-chasing-squares .square:nth-child(1) {
}

.preloader-chasing-squares .square:nth-child(2) {
  animation-delay: 0.7s;
}

.preloader-chasing-squares .square:nth-child(3) {
  animation-delay: 2.1s;
}

.preloader-chasing-squares .square:nth-child(4) {
  animation-delay: 1.4s;
}

@keyframes focusfade {
  0% {
    opacity: 0.5;
    background: #4a63e1;
  }

  30% {
    opacity: 0.5;
    background: #35cbd4;
  }

  60% {
    opacity: 0;
  }

  75% {
    opacity: 0;
    background: #72a84b;
  }

  100% {
    opacity: 0.5;
    background: #b9da34;
  }
}

.yorbitSprite {
  background-image: url('../src/assets/images/YORBIT_Sprite.png');
  background-repeat: no-repeat;
}
.resetSmallSpriteIcon {
  background-position: -356px -298px;
  width: 25px;
  height: 25px;
}
.downloadMaterialIcon {
  background-position: -426px -76px;
  width: 50px;
  height: 50px;
}

.accessMaterialIcon {
  background-position: -678px -108px;
  width: 50px;
  height: 50px;
}
.downloadSpriteIcon {
  background-position: -478px -292px;
  width: 33px;
  height: 33px;
}
.downloadSpriteIconWhite {
  background-position: -478px -327px;
  width: 33px;
  height: 33px;
}
.uploadSpriteIcon {
  background-position: -482px -331px;
  width: 25px;
  height: 25px;
  transform: rotateX(180deg);
}
.calendarSpirteIcon {
  background-position: -306px -80px;
  width: 25px;
  height: 25px;
}
.editSpriteIcon {
  background-position: -81px -323px;
  width: 25px;
  height: 25px;
}
.roundedCrossSpriteIcon {
  background-position: -44px -391px;
  width: 25px;
  height: 25px;
}
.roundedTickSpriteIcon {
  background-position: -7px -323px;
  width: 25px;
  height: 25px;
}

.exclamationSpriteIcon{
    background-position: -309px -126px;
    width: 18px;
    height: 18px;
}

.nonCompliantIcon {
  background-position: -309px -126px;
  width: 18px;
  height: 18px;
}
.nonCompliantIconPos {
  position: absolute;
  top: -6px;
  right: 0;
}
.iconSmiley1,
.iconSmileyVeryHappy {
  background-position: -230px -2px;
  width: 72px;
  height: 68px;
}
.iconSmiley2,
.iconSmileyHappy {
  background-position: -514px -2px;
  width: 72px;
  height: 68px;
}
.iconSmiley3,
.iconSmileyNeutral {
  background-position: -443px -2px;
  width: 72px;
  height: 68px;
}
.iconSmiley4,
.iconSmileySad {
  background-position: -373px -2px;
  width: 72px;
  height: 68px;
}
.iconSmiley5,
.iconSmileyVerySad {
  background-position: -159px -2px;
  width: 72px;
  height: 68px;
}
.iconSmiley6,
.iconSmileyThinking {
  background-position: -302px -2px;
  width: 72px;
  height: 68px;
}

.btn-disabled {
  cursor: not-allowed !important;
  opacity: 0.5 !important;
}

.start_selected {
  color: $filled_star !important;
  cursor: pointer;
}
.start_unselected {
  color: $unfilled_star !important;
  cursor: pointer;
}
.disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.cloud-access-denied {
  background-position: -510px -235px;
  width: 52px;
  height: 35px;
}
.cloud-access-granted {
  background-position: -510px -155px;
  width: 52px;
  height: 35px;
}
.cloud-access-waiting {
  background-position: -510px -194px;
  width: 52px;
  height: 35px;
}

.spriteIconCorrect {
  background-position: -489px -139px;
  width: 15px;
	height: 11px;
}

// ::ng-deep .mat-snack-bar-container{
//   background: #52606d !important;
//   color: #fff  !important;
//   -webkit-transition: width 2s  !important; /* Safari */
//   transition: width 2s  !important;
//   min-width:250px !important;
// }
textarea {
  resize: none;
}

/**
* Following For IE only
*/
yorbit-home {
  flex: 0 0 auto;
}
input[type='text']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
yorbit-lp-course-description {
  flex: 0 0 auto;
  width: 100%;
}
yorbit-details-page-course {
  flex: 0 0 auto;
}
.icon-declare-flag-Prerequisite {
  background-position: -384px -330px;
  width: 25px;
  height: 25px;
  zoom: 0.6;
  display: inline-block;
}

//Declare icon on FAQ
.icon-declare-flag-faq {
  background-position: -375px -330px;
  width: 31px;
  height: 27px;
  padding: 0px 5px;
}

.questionHt {
  height: 30px;
}

.FAQBadgeImg {
  width: 100px;
  height: 100px;
}

.flexContainer {
  display: flex;
  height: 198px;
  flex-direction: column;
  padding-top: 10px;
}
.flexItems {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.whats-new-icon {
  // background-position: -217px -361px;
  // width: 63px;
  // height: 63px;
  background-position: -600px -400px;
  width: 67px;
  height: 66px;
  border: transparent;
  border-radius: 50%;
  @media screen and (max-width: 600px) {
    margin-top: 20px;
  }
  // box-shadow: 0px 0px 10px 2px #a1a0a0;
  //border-radius: 30px;
}
.whats-new-icon:hover {
  border-radius: 30px;
  // animation: pulse 2s infinite;
  //  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
  //  -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
  //  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 16px #fff;
}

.documentDownloadIconRounded {
  background-position: -475px -81px;
  width: 45px;
  height: 45px;
  cursor: pointer;
}
.documentDownloadIconRoundedDark {
  background-position: -158px -334px;
  width: 45px;
  height: 45px;
  cursor: pointer;
}
.documentDownloadIcon {
  background-position: -276px -339px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(241, 235, 235, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 5px rgba(236, 229, 229, 0.3);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.3);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
  }
}

.floatingActionBar {
  background-position: -93px -2px;
  width: 62px;
  height: 182px;
  @media screen and (max-width: 600px) {
    margin-top: 20px;
  }
}

.floatingHelpIcon {
  background-position: -2px -128px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.floatingHelpIconDark,
.floatingHelpIcon:hover {
  background-position: -46px -128px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.floatingHelpPos {
  position: absolute;
  top: 20px;
  left: 11px;
}

.floatingFAQIcon {
  background-position: -2px -187px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.floatingFAQIconDark,
.floatingFAQIcon:hover {
  background-position: -46px -187px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.floatingFAQPos {
  position: absolute;
  top: 74px;
  left: 11px;
}

.floatingFeedbackIcon {
  background-position: -2px -238px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.floatingFeedbackIconDark,
.floatingFeedbackIcon:hover {
  background-position: -46px -238px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.floatingFeedbackPos {
  position: absolute;
  top: 120px;
  left: 11px;
}
.floatingActionBarHomePage {
  width: 62px;
  height: 190px;
  border-radius: 34px;
  border: 1px solid white;
  background-color: rgba(0, 0, 0, 0.2);
  background-image: none;
  @media screen and (max-width: 600px) {
    margin-top: 20px;
  }
  .learningParnterDetails {
    position: absolute;
    top: 5px;
    left: 2px;
    .learningPartnerIcon {
      background-position: -530px -425px;
      width: 60px;
      height: 75px;
      border: transparent;
      cursor: pointer;
    }
    .learningPartnerIcon:hover,
    .learningPartnerIcon.lpPopoverOpen {
      background-position: -456px -425px;
      width: 60px;
      height: 75px;
      border: transparent;
      cursor: pointer;
      // animation: pulse 2s infinite;
      //  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
      //  -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
      //  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
      //box-shadow: 0px 0px 16px #fff;
    }
  }
  .floatingHelpPos {
    top: 70px !important;
  }
  .floatingFAQPos {
    //top: 124px !important;
  }
  .floatingFeedbackPos {
    //top: 170px !important;
  }
}

.internalCloudLabSmall {
  background-position: -157px -394px;
  width: 20px;
  height: 24px;
}
.externalCloudLabSmall {
  background-position: -185px -395px;
  width: 20px;
  height: 24px;
}
.internalCloudLabLarge {
  background-position: -146px -443px;
  width: 50px;
  height: 55px;
}
.externalCloudLabLarge {
  // background-position: -94px -434px;
  // width: 50px;
  // height: 55px;
  background-position: -90px -431px;
  width: 53px;
  height: 64px;
}
.courseIcon101 {
  background-position: -693px -351px;
  width: 20px;
  height: 30px;
}
.courseIcon201 {
  background-position: -693px -425px;
  width: 20px;
  height: 30px;
}
.courseIcon301 {
  background-position: -693px -387px;
  width: 20px;
  height: 30px;
}
.courseIconDegreed {
background-position: -665px -464px;
  width: 20px;
  height: 30px;
}
.packageIcon101 {
  background-position: -720px -354px;
  width: 21px;
  height: 30px;
}
.packageIcon201 {
  background-position: -720px -425px;
  width: 21px;
  height: 30px;
}
.packageIcon301 {
  background-position: -720px -390px;
  width: 21px;
  height: 30px;
}
.bg101 {
  background-color: $_101;
}

.bg201 {
  background-color: $_201;
}

.bg301 {
  background-color: $_301;
}
.opacity1 {
  opacity: 1 !important;
  cursor: not-allowed;
}

.prev-icon-white {
  background-position: 0px -25px;
  width: 20px;
  height: 34px;
  cursor: pointer;
}
.next-icon-white {
  background-position: -58px -24px;
  width: 20px;
  height: 35px;
  cursor: pointer;
}

.del-icon-grey-small {
  background-position: -464px -134px;
  width: 18px;
  height: 18px;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mandatory-field-indicator {
  color: red;
  font-weight: bold;
  font-size: 10px;
}
.headingfordownpage{
  font-size: 20px;
}
.sideheadingfordownpage{
  font-size: 16px;
}
.mat-step-header:focus{
  background-color: transparent !important;
}
.mat-step-header:hover{
  background-color: transparent !important;
}

.mat-paginator-page-size
{
  color: #394959;
}
.mat-paginator-range-label
{
  color:#394959;
}