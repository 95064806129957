
/*lighter shades*/
$_101: #318cf1;
$_201: #22b1a8;
$_301: #f99723;
$_degreed: #312e7f;
/*darker shades*/
$_101_variant:#2374cd;
$_201_variant:#19958d;
$_301_variant:#e37c18;

$_background_lighter_grey:#f6f6f6;
$_background_light:#646e86;
$_background_medium:#47526b;
$_background_dark:#282d39;

$_btn_blue:#394959;
$popup_btn_color:#394959;
$popup_header_color:#6d7f91;
$popup_footer_color:#52606d;
$popup_bg_color:#eaeaea;

$filled_star:#455A64;
$unfilled_star:#bdbcbc;

$light_grey_border: #bdbcbc;
$mandatory_red_note:red;


